import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

// Post codes in Japan has 7 number
const POSTCODE_LENGTH = 7

export default class extends Controller {
  connect() {
    this.target = document.querySelector(this.element.dataset.target)
    this.debouncedCssChange = _.debounce(this.cssChange, 250)
  }

  onInput() {
    this.debouncedCssChange()
  }

  cssChange() {
    const hasValue =
      this.element.value.length === POSTCODE_LENGTH && this.target.value !== ''
    this.target.classList.toggle('text-blueGray-700', hasValue)
  }
}
