import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  connect() {
    this.addTableWraps()
    this.addDialog()
    this.addPoint()
    this.addNotice()
    this.addCalculator()
    this.addAnchor()
    this.addRecommend()
    this.addOutboundLinkA()
    this.addOutboundLinkB()
  }

  addTableWraps() {
    const tables = this.contentTarget.querySelectorAll('table')

    if (tables.length === 0) return

    tables.forEach((table) => {
      const wrap = document.createElement('div')
      wrap.setAttribute('class', 'overflow-scroll')
      table.parentNode.insertBefore(wrap, table.nextSibling)
      wrap.appendChild(table)
    })
  }

  addDialog() {
    const dialogs = this.contentTarget.querySelectorAll(
      '[class^="column__dialog"]'
    )

    dialogs.forEach((dialog) => {
      const dialogWrap = document.createElement('div')
      const personImg = document.createElement('img')
      const number = dialog.classList[0].match(/\d+$/)[0]
      personImg.setAttribute(
        'src',
        `https://static.ins.minkabu.jp/icon/column_icon_${number}.png`
      )
      dialogWrap.setAttribute('class', 'column__dialog-wrapper')
      dialog.parentNode.insertBefore(dialogWrap, dialog.nextSibling)
      dialogWrap.appendChild(personImg)
      dialogWrap.appendChild(dialog)
    })
  }

  addPoint() {
    this.__wrapWithIcon(
      'column__point',
      'fa-regular fa-lightbulb fa-xl',
      '#FB923C'
    )
  }

  addNotice() {
    this.__wrapWithIcon(
      'column__notice',
      'fa-solid fa-triangle-exclamation fa-xl',
      '#FACC15'
    )
  }

  addCalculator() {
    this.__wrapWithIcon(
      'column__calculator',
      'fa-solid fa-calculator fa-xl',
      '#2DD4BF'
    )
  }

  addAnchor() {
    const anchors = this.contentTarget.querySelectorAll('.column__anchor')

    anchors.forEach((anchor) => {
      const anchorWrapper = document.createElement('div')
      const iconContainer = document.createElement('div')
      const icon = document.createElement('i')
      const text = anchor.innerText
      anchorWrapper.setAttribute('class', 'column__anchor-wrapper')
      iconContainer.setAttribute(
        'class',
        'self-start flex-shrink-0 flex items-center justify-center w-4 h-6'
      )
      icon.setAttribute('class', 'fa-solid fa-circle-chevron-right')
      icon.setAttribute('style', 'color: #1D4ED8')
      anchor.parentNode.insertBefore(anchorWrapper, anchor.nextSibling)
      anchorWrapper.appendChild(anchor)
      anchor.innerHTML = ''
      anchor.appendChild(iconContainer)
      iconContainer.appendChild(icon)
      anchor.innerHTML += text
    })
  }

  addRecommend() {
    const columns = this.contentTarget.querySelectorAll('.column__recommend')

    columns.forEach((column) => {
      const slug = column.href.split('/').pop()
      const xhr = new XMLHttpRequest()
      xhr.open('Get', `${window.location.origin}/columns/render/${slug}`, true)

      xhr.onload = function () {
        if (xhr.status === 200) {
          const columnWrapper = document.createElement('div')
          columnWrapper.setAttribute('class', 'column__recommend')
          columnWrapper.innerHTML = xhr.responseText
          column.parentNode.insertBefore(columnWrapper, column.nextSibling)
          column.remove()
        } else {
          column.remove()
        }
      }

      xhr.send()
    })
  }

  addOutboundLinkA() {
    const elements = this.contentTarget.querySelectorAll(
      '.column__outbound-link-a'
    )

    elements.forEach((element) => {
      const elementWrapper = document.createElement('div')
      const elementReferral = document.createElement('div')
      elementWrapper.setAttribute('class', 'column__outbound-link-a-wrapper')
      elementReferral.setAttribute('class', 'column__outbound-link-a-referral')
      element.setAttribute('rel', 'noopener nofollow')
      element.setAttribute('target', '_blank')
      elementReferral.innerText = '参照'
      element.parentNode.insertBefore(elementWrapper, element.nextSibling)
      elementWrapper.appendChild(elementReferral)
      elementWrapper.appendChild(element)
    })
  }

  addOutboundLinkB() {
    const elements = this.contentTarget.querySelectorAll(
      '.column__outbound-link-b'
    )

    elements.forEach((element) => {
      const elementContainer = document.createElement('div')
      const elementText = document.createElement('div')
      const icon = document.createElement('i')
      elementContainer.setAttribute(
        'class',
        'column__outbound-link-b-container'
      )
      elementText.setAttribute('class', 'column__outbound-link-b-text')
      icon.setAttribute('class', 'fa-solid fa-arrow-up-right-from-square fa-lg')
      icon.setAttribute('style', 'color: #CBD5E1')
      element.setAttribute('rel', 'noopener nofollow')
      element.setAttribute('target', '_blank')
      elementText.innerText = element.innerText
      elementContainer.appendChild(elementText)
      elementContainer.appendChild(icon)
      element.innerHTML = elementContainer.outerHTML
    })
  }

  __wrapWithIcon(targetClassName, iconClassName, iconColor) {
    const elements = this.contentTarget.querySelectorAll(`.${targetClassName}`)
    elements.forEach((element) => {
      const elementWrapper = document.createElement('div')
      const iconContainer = document.createElement('div')
      const icon = document.createElement('i')
      elementWrapper.setAttribute('class', `${targetClassName}-wrapper`)
      iconContainer.setAttribute(
        'class',
        'flex-shrink-0 flex items-center justify-center w-6 h-6'
      )
      icon.setAttribute('class', `${iconClassName}`)
      icon.setAttribute('style', `color: ${iconColor};`)
      element.parentNode.insertBefore(elementWrapper, element.nextSibling)
      iconContainer.appendChild(icon)
      elementWrapper.appendChild(iconContainer)
      elementWrapper.appendChild(element)
    })
  }
}
