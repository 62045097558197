import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenElement']

  connect() {
    this.hiddenElementTargets.forEach((element) => {
      const target = element.querySelector('.js-check-content-target')

      if (target.textContent.replace(/\s/g, '').length === 0) {
        element.classList.add('hidden')
      }
    })
  }
}
