import { Controller } from '@hotwired/stimulus'

const lineClampHeightMap = {
  lineClamp2: 32,
}

export default class extends Controller {
  static targets = ['lineClamp', 'detailModal']

  static values = {
    lineClamp: { type: String, default: 'lineClamp2' },
  }

  connect() {
    this.showDetailButton()
  }

  showDetailButton() {
    if (
      this.lineClampTarget.scrollHeight >
      lineClampHeightMap[this.lineClampValue]
    ) {
      this.detailModalTarget.classList.remove('hidden')
    }
  }
}
