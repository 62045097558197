export default (initialCount, initialOrderType) => ({
  selectedList: [],
  shownSelected: false,
  orderType: initialOrderType,
  filteredCount: initialCount,
  init() {
    this.$nextTick(() => {
      this.filteredCount = this.getRowData().length
      this.reorder(this.orderType)
    })
    this.$watch('orderType', (value) =>
      this.$nextTick(() => {
        this.reorder(value)
      })
    )
  },
  getRowData() {
    return document.querySelectorAll('.data_row')
  },
  reorder(type) {
    const sortArr = Array(...this.getRowData())
    const orderKey = `data-${type}`
    sortArr.sort((x, y) => x.getAttribute(orderKey) - y.getAttribute(orderKey))
    sortArr.forEach((tr) => {
      tr.parentNode.appendChild(tr)
    })
  },
  numberWithDelimiter(number, delimiter, separator) {
    separator = separator || '.'
    delimiter = delimiter || ','

    if (number === 0) {
      return '---'
    } else {
      const split = String(number).split(separator)

      split[0] = split[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + delimiter)

      return split.join(separator)
    }
  },
})
