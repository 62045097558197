import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'
import LocalStorage from '../../modules/local_storage'
import { findCurrentFixedTabContentElements } from '../../utils/fixed_styles_setting'
import findProductIdsWithSearchString from '../../utils/with_search_string'

const updateCheckedCount = (container) => {
  // add setTimeout() to wait for Browser auto checked, then execute checkebox checked and count
  setTimeout(() => {
    const checkedNumbers = document.querySelectorAll(
      '.follow-up-checked-number'
    )
    const checkedList = []
    const containers = Array.from(
      container.querySelectorAll('input[name="company_choose[]"]:checked')
    )
    containers.forEach((node) => {
      checkedList.push(node.value)
    })
    const chkNum = _.uniq(checkedList).length
    checkedNumbers.forEach((checkedNumber) => {
      const numberBox = checkedNumber
      numberBox.textContent = `${chkNum}件`
    })
  }, 250)
}
export default class extends Controller {
  static targets = ['container', 'checkedNumber']

  connect() {
    this.storage = new LocalStorage()
    this.pageType = window.location.pathname.match(/\w+/g)[0] || ''
    this.companyIds = []
    this.choosedDirectIds = []
    this.choosedDirectItems = {}
    this.checkedIds = findProductIdsWithSearchString()
    this.inputs = this.containerTarget.querySelectorAll(
      'input[name="company_choose[]"]'
    )
    this.button = this.containerTarget.querySelector('.step1-button')
    const isMobile = window.matchMedia('(max-width: 768px)').matches
    const spForm = document.querySelector('.sp-form-content')
    const pcForm = document.querySelector('.pc-form-content')

    if (isMobile && spForm !== null) {
      this.formName = '.sp-form-content'
    }

    if (isMobile === false && pcForm !== null) {
      this.formName = '.pc-form-content'
    }

    // Only in car_page need to judge direct item at present
    if (this.pageType === 'car') {
      this.getDirectItemData()
    }

    if (this.button) {
      this.button.setAttribute('data-action', 'click->follow-up#clickHandler')
    }

    if (this.checkedIds.length > 0) {
      this.collectCheckedCompanyIdsFromParams()
      this.setStorage()
    }

    this.count()
  }

  collectCheckedCompanyIdsFromParams() {
    const ids = []
    this.inputs.forEach((input) => {
      if (this.checkedIds.includes(input.value)) {
        input.setAttribute('checked', true)
        ids.push(input.value)
      }
    })
    this.companyIds = ids
  }

  collectCheckedCompanyIds() {
    const ids = []
    this.inputs.forEach((input) => {
      if (input.checked) {
        ids.push(input.value)
      }
    })
    this.companyIds = ids
  }

  collectNonDirectCompanyIds() {
    if (this.companyIds.length > 0) {
      return
    }

    const ids = []
    this.inputs.forEach((input) => {
      if (this.directIds !== undefined) {
        if (!this.directIds.includes(Number(input.value))) {
          ids.push(input.value)
        }
      } else {
        ids.push(input.value)
      }
    })

    this.companyIds = ids
  }

  clickHandler(e) {
    e.preventDefault()
    this.collectCheckedCompanyIds()

    // Only in car_page need to judge direct item at present
    if (this.pageType === 'car') {
      this.ensureDirectItem()
    }

    this.collectNonDirectCompanyIds()
    this.setStorage()
    window.location.href = e.currentTarget.href

    return true
  }

  setStorage() {
    this.storage.setValue(`${this.pageType}_item_ids_on_step1`, this.companyIds)
    this.storage.setValue(
      `${this.pageType}_direct_items_on_step1`,
      this.choosedDirectItems
    )
  }

  /* eslint-disable class-methods-use-this */
  count() {
    const { currentTabContent } = findCurrentFixedTabContentElements(document)
    const isRankingPage = window.location.pathname.match(/ranking/) !== null
    const targetContainer = isRankingPage
      ? this.containerTarget
      : currentTabContent

    updateCheckedCount(targetContainer)
  }
  /* eslint-enable class-methods-use-this */

  getDirectItemData() {
    const data = document.querySelector('#direct_items_data').dataset
    this.directItems = JSON.parse(data.directItems)
    this.directIds = this.directItems.map((item) => item.id)
  }

  ensureDirectItem() {
    this.choosedDirectIds = this.companyIds.flatMap((id) =>
      this.directIds.includes(parseInt(id, 10)) ? [parseInt(id, 10)] : []
    )
    this.choosedDirectItems = {
      ids: this.choosedDirectIds,
      companyNames: this.choosedDirectIds.map(
        (id) => this.directItems.find((item) => item.id === id).company_name
      ),
      urls: this.choosedDirectIds.map(
        (id) => this.directItems.find((item) => item.id === id).url
      ),
    }
  }

  setTabTypeWithStorage() {
    const currentTab = document.querySelector('.tab__item.active')

    if (currentTab === null) {
      return
    }

    const id = currentTab.getAttribute('data-tab-panel-id-param')
    this.storage.setValue(`${this.pageType}_current_tab`, id)
  }
}
