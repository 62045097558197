import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.target = document.querySelector(this.element.dataset.target)
    this.optionLabels = this.target.querySelectorAll('div.form__radio-group')
    this.firstOption = this.firstOptionLabel.querySelector('input')
    this.nextOption = this.target.querySelector('input:not([checked])')

    this.inputs = this.element.querySelectorAll('input')
    this.inputs.forEach((input) => {
      input.setAttribute('data-action', 'change->switch-hidden#toggle')
    })

    this.setDefaultChecked(this.currentChoose)
  }

  toggle(e) {
    this.setDefaultChecked(e.currentTarget.value)
  }

  get currentChoose() {
    return this.element.querySelector('input[checked]').value
  }

  get firstOptionLabel() {
    return this.optionLabels[0]
  }

  get firstOptionIsChecked() {
    return this.firstOption.checked
  }

  setDefaultChecked(value) {
    if (value === '1') {
      this.firstOptionLabel.classList.add('hidden')
      this.firstOptionLabel.setAttribute('hidden', '')
      if (this.firstOptionIsChecked) {
        this.nextOption.checked = true
        this.nextOption.dispatchEvent(new Event('change'))
      }
    } else {
      this.firstOptionLabel.classList.remove('hidden')
      this.firstOptionLabel.removeAttribute('hidden')
    }
  }
}
