import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['suggest']

  connect() {
    if (this.suggestTarget !== null) {
      window.addEventListener('scroll', () => {
        this.showFooterSuggest(this.suggestTarget)
      })
    }
  }

  /* eslint-disable class-methods-use-this */
  showFooterSuggest(suggest) {
    // scroll down 100px should show the item
    if (window.scrollY > 200) {
      suggest.classList.remove('translate-y-full')
      suggest.classList.add('translate-y-0')
    } else {
      suggest.classList.remove('translate-y-0')
      suggest.classList.add('translate-y-full')
    }
  }
  /* eslint-enable class-methods-use-this */
}
