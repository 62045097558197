import { Controller } from '@hotwired/stimulus'
import LocalStorage from '../../modules/local_storage'

export default class extends Controller {
  connect() {
    const pageType = window.location.pathname.match(/\w+/g)[0] || ''
    this.storage = new LocalStorage()
    this.ensureStep1Page(pageType)
  }

  ensureStep1Page(type) {
    this.choosedDirectItems = this.storage.getValues(
      `${type}_direct_items_on_step1`
    )
    const isStep1Page = /^.+\/step1$/.test(window.location.pathname)
    const isDirectItemIdsExist =
      !!this.choosedDirectItems && this.choosedDirectItems.ids.length > 0

    if (isStep1Page && isDirectItemIdsExist) {
      this.editDirectItem()
      this.showDirectItem()
    }
  }

  /* eslint-disable class-methods-use-this */
  showDirectItem() {
    document.querySelector('#direct_items').classList.remove('hidden')
  }
  /* eslint-enable class-methods-use-this */

  editDirectItem() {
    document.querySelector('#direct_items').innerHTML = ''

    this.choosedDirectItems.companyNames.forEach((companyName, index) => {
      const url = this.choosedDirectItems.urls[index]

      const div = document.createElement('div')
      const paragraph = document.createElement('p')
      const anchor = document.createElement('a')

      div.setAttribute(
        'class',
        'p-3 mb-4 border border-blueGray-200 bg-blueGray-50 rounded'
      )
      paragraph.setAttribute('class', 'text-sm text-blueGray-500')

      anchor.setAttribute('href', url)
      anchor.setAttribute('class', 'text-blue-700')
      anchor.setAttribute('rel', 'noopener nofollow sponsored')
      anchor.setAttribute('target', '_blank')
      anchor.innerHTML = '公式WEBページ'

      paragraph.append(
        companyName,
        'の自動車保険はインターネット加入専門の商品となります。お見積もり、申し込みは',
        anchor,
        'にてお願いいたします。'
      )

      div.appendChild(paragraph)
      document.querySelector('#direct_items').appendChild(div)
    })
  }
}
