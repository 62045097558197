import _ from 'lodash'
import { Controller } from '@hotwired/stimulus'
import LocalStorage from '../../modules/local_storage'
import findProductIdsWithSearchString from '../../utils/with_search_string'
import { findCurrentFixedTabContentElements } from '../../utils/fixed_styles_setting'

const checkedCheckboxes = () => {
  const productIds = findProductIdsWithSearchString()
  const checkboxes = document.querySelectorAll('input[name="company_choose[]"]')
  checkboxes.forEach((box) => {
    if (productIds.includes(box.value)) {
      const checkbox = box
      checkbox.checked = true
    }
  })
}

const updateCheckedNumberCount = () => {
  const checkedNumbers = document.querySelectorAll('.follow-up-checked-number')
  const { currentTabContent } = findCurrentFixedTabContentElements(document)
  const checkedList = []
  const containers = Array.from(
    currentTabContent.querySelectorAll('input[name="company_choose[]"]:checked')
  )
  containers.forEach((node) => {
    checkedList.push(node.value)
  })

  const chkNum = _.uniq(checkedList).length

  checkedNumbers.forEach((checkedNumber) => {
    const numberBox = checkedNumber
    numberBox.textContent = `${chkNum}件`
  })
}

const cleanUpCheckedNumberCount = () => {
  const checkedNumbers = document.querySelectorAll('.follow-up-checked-number')

  if (checkedNumbers !== null) {
    checkedNumbers.forEach((checkedNumber) => {
      const number = checkedNumber
      number.textContent = '0件'
    })
  }
}

const cleanUpCheckedInAllCheckboxes = () => {
  const forms = document.querySelectorAll(
    'form[data-follow-up-target="container"]'
  )

  if (forms !== null) {
    const checkboxList = []
    forms.forEach((form) => {
      const checkboxes = Array.from(
        form.querySelectorAll('input[name="company_choose[]"]:checked')
      )
      checkboxList.push(checkboxes)
    })

    checkboxList.flat().forEach((checkbox) => {
      const el = checkbox
      el.checked = false
    })
  }
}
export default class extends Controller {
  static targets = ['panelTabs', 'panelLabel', 'panelBody', 'panelContent']

  connect() {
    this.storage = new LocalStorage()
    const { pathname } = window.location
    this.pageType = pathname.match(/\w+/g)[0] || ''
    const isConsultantPage = pathname.match(/^.*(consultant)$/) !== null
    const isNotFromOtherPage =
      document.referrer.match(/^.+consultant.*/) !== null
    this.hasSearchString = window.location.search.length > 0

    if (isConsultantPage && isNotFromOtherPage && this.hasSearchString) {
      this.switchTabWithStorage()
    }

    const defaultPanel = this.panelTabsTarget.getAttribute(
      'data-tab-panel-default-panel'
    )
    this.switchLabel(defaultPanel)
    this.displayContent(defaultPanel)
  }

  switch({ params: { id } }) {
    this.switchLabel(id)
    this.displayContent(id)
    cleanUpCheckedNumberCount()
    cleanUpCheckedInAllCheckboxes()

    if (this.hasSearchString) {
      checkedCheckboxes()
      updateCheckedNumberCount()
    }
  }

  switchLabel(panelId) {
    this.panelTabsTarget
      .querySelectorAll('[data-tab-panel-target=panelLabel]')
      .forEach((label) => label.classList.remove('active'))
    const panel = this.panelTabsTarget.querySelector(`#tab-label-${panelId}`)
    if (panel) {
      panel.classList.add('active')
    }
  }

  displayContent(panelId) {
    this.panelBodyTarget
      .querySelectorAll('[data-tab-panel-target=panelContent]')
      .forEach((content) => content.classList.add('hidden'))
    const panel = this.panelBodyTarget.querySelector(`#tab-content-${panelId}`)
    if (panel) {
      panel.classList.remove('hidden')
    }
  }

  switchTabWithStorage() {
    const withStorageValue = this.storage.getValues(
      `${this.pageType}_current_tab`
    )
    const withPanelLabel = this.panelLabelTarget.getAttribute(
      'data-tab-panel-id-param'
    )
    const id = withStorageValue || withPanelLabel
    this.storage.setValue(`${this.pageType}_current_tab`, id)
    this.panelTabsTarget.setAttribute('data-tab-panel-default-panel', id)
  }
}
