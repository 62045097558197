import { Controller } from '@hotwired/stimulus'
import LocalStorage from '../../modules/local_storage'

export default class extends Controller {
  static targets = ['form']

  static values = {
    formDirty: { type: Boolean, default: true },
    formSubmitting: { type: Boolean, default: false },
  }

  connect() {
    this.addFormDirtyListener()

    window.addEventListener('beforeunload', (e) => {
      if (!this.formSubmittingValue && this.formDirtyValue)
        e.returnValue = false

      return e.returnValue
    })

    this.setProductInfo()
  }

  setProductInfo() {
    this.storage = new LocalStorage()
    const idInput = this.element.querySelector('#inflow_insurance_item_id')
    const nameInput = this.element.querySelector('#inflow_insurance_item_name')
    if (idInput !== null) {
      idInput.value = this.storage.getValues('inflow_insurance_item_id')
    }

    if (nameInput !== null) {
      nameInput.value = this.storage.getValues('inflow_insurance_item_name')
    }
  }

  setFormSubmitting() {
    this.formSubmittingValue = true
  }

  addFormDirtyListener() {
    if (this.formDirtyValue) return

    const selects = this.formTarget.querySelectorAll('select')
    const radios = this.formTarget.querySelectorAll("input[type='radio']")
    const checkboxs = this.formTarget.querySelectorAll("input[type='checkbox']")
    const textInputs = this.formTarget.querySelectorAll("input[type='text']")

    selects.forEach((select) => {
      select.addEventListener('click', () => {
        this.formDirtyValue = true
      })
    })

    radios.forEach((radio) => {
      radio.addEventListener('click', () => {
        this.formDirtyValue = true
      })
    })

    checkboxs.forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        this.formDirtyValue = true
      })
    })

    textInputs.forEach((input) => {
      input.addEventListener('change', () => {
        this.formDirtyValue = true
      })
    })
  }
}
