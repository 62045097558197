import _ from 'lodash'
import { findCurrentFixedTabContentElements } from './fixed_styles_setting'

/* eslint-disable no-use-before-define */
// It SHOULD define rebind scroll event separately to have better motion effect.
// .main-scroll-area
// .fixed-scroll-area scroll-area__translate3d
//   .fixed-scroll-area-table scroll-area__render-scroll

// p.s. scroll-area__translate3d is resolved fixed problem in safari

const rebindFixedScrollEvent = _.debounce((element) => {
  element.addEventListener('scroll', fixedScrollHandler)
}, 700)

const rebindMainScrollEvent = _.debounce((element) => {
  element.addEventListener('scroll', mainScrollHandler)
}, 600)

function fixedScrollHandler(e) {
  let mainArea = document.querySelector('.main-scroll-area')
  const currentTab = document.querySelector('.tab__item.active')

  // Find the corresponding main area when having tabs.
  if (currentTab) {
    const currentTabId = currentTab.id.match(/^tab-label-(.+)$/)[1]
    mainArea = document.querySelector(`div#${currentTabId}.main-scroll-area`)
  }

  mainArea.removeEventListener('scroll', mainScrollHandler)
  mainArea.scroll(e.target.scrollLeft, 0)
  rebindMainScrollEvent(mainArea)
}

function mainScrollHandler(e) {
  let fixedArea = document.querySelector('.fixed-scroll-area-table')
  const currentTab = document.querySelector('.tab__item.active')

  // Find the corresponding fixed area when having tabs.
  if (currentTab) {
    const currentTabId = currentTab.id.match(/^tab-label-(.+)$/)[1]
    fixedArea = document.querySelector(
      `div#tab-content-${currentTabId} .fixed-scroll-area .fixed-scroll-area-table`
    )
  }

  fixedArea.removeEventListener('scroll', fixedScrollHandler)
  fixedArea.scroll(e.target.scrollLeft, 0)
  rebindFixedScrollEvent(fixedArea)
}
/* eslint-enable no-use-before-define */

function syncScrollArea(containerTarget = document) {
  const { fixedScrollAreaElement, mainScrollAreaElement } =
    findCurrentFixedTabContentElements(containerTarget)
  const fixedScrollAreaContent = fixedScrollAreaElement.querySelector(
    '.fixed-scroll-area-table'
  )

  if (
    fixedScrollAreaContent !== undefined &&
    mainScrollAreaElement !== undefined
  ) {
    fixedScrollAreaContent.addEventListener('scroll', fixedScrollHandler)
    mainScrollAreaElement.addEventListener('scroll', mainScrollHandler)
  }
}

export default syncScrollArea
