import { Controller } from '@hotwired/stimulus'

const findQuestionContainer = (target) => {
  const name = target.name.match(/^.*\[(.+)\]$/)[1]
  return document.querySelector(`[name="${name}"]`)
}

export default class extends Controller {
  static targets = ['targetField', 'checkBox']

  connect() {
    const originDataActionAttribute =
      this.targetFieldTarget.getAttribute('data-action') || ''
    this.targetFieldTarget.setAttribute(
      'data-action',
      `${originDataActionAttribute} input->clean-up-field#inputHandler`
    )
    this.checkBoxTarget.setAttribute(
      'data-action',
      'click->clean-up-field#clickHandler'
    )

    if (this.checkBoxTarget.checked) {
      this.clickHandler()
    }
  }

  inputHandler() {
    if (this.checkBoxTarget.checked === false) return

    this.checkBoxTarget.checked = false
  }

  clickHandler() {
    if (this.targetFieldTarget.value === '') {
      this.removeError()
      return
    }

    this.targetFieldTarget.value = ''
  }

  removeError() {
    const container = findQuestionContainer(this.targetFieldTarget)
    const errorMsgElement = container.querySelector('.input__alert')
    if (errorMsgElement) {
      errorMsgElement.remove()
    }

    const errorContainer = container.querySelector(`.input-box--error`)
    if (errorContainer) {
      errorContainer.classList.remove(`input-box--error`)
    }
  }
}
