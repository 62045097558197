import { findCurrentFixedTabContentElements } from './fixed_styles_setting'

// ****** Switch Hidden Element ****** //
// Setting the className for elements of clone and target before using the switchHiddenElements function.
// Clone element SHOULD add "cloned-el fixed-el-{1} hidden" class name.
// Target element SHOULD add "target-el-{1}" className.
// Adjust main-scroll-area element padding top.

// *** normal *** //
// div data-controller="fixed-area" data-fixed-area-target="container"
// .is-fixed-hidden-el-area
//   .cloned-el fixed-el-{1} hidden
// div(target area)
//   .target-el-{1}

// *** has tab *** //
// div data-controller="fixed-area" data-fixed-area-target="container"
// #tab-content-${ID} .is-fixed
// label(tab panel).active(default tab)
//   .is-fixed-hidden-el-area
//     .cloned-el fixed-el-{1} hidden
//   div(target area)
//     .target-el-{1}

const hiddenElementsInfoInMobile = {}
const hiddenElementsInfoInPC = {}
const toggleClonedElements = (container) => {
  const clonedEls = container.querySelectorAll('.cloned-el')

  if (clonedEls !== undefined) {
    let cloneElementsHeighTotal = 0

    clonedEls.forEach((el) => {
      const fixedElNumber = el.className.match(/^.*fixed-el-(\d+).*$/)[1]
      const targetElementClassName = `.target-el-${fixedElNumber}`
      const { mainScrollAreaElement } =
        findCurrentFixedTabContentElements(container)
      const originElement = mainScrollAreaElement
        .querySelector(targetElementClassName)
        .getBoundingClientRect()
      const isMobile = window.matchMedia('(max-width: 768px)').matches

      if (
        hiddenElementsInfoInMobile[`${targetElementClassName}`] === undefined &&
        isMobile
      ) {
        const fixedAreaBottom = container
          .querySelector('.is-fixed-hidden-el-area')
          .getBoundingClientRect().bottom
        const headerHeigh =
          document.querySelector('header').getBoundingClientRect().height || 0
        const topMenuHeigh =
          document
            .querySelector('[class^="header__sp-nav-menu-wrapper"]')
            .getBoundingClientRect().height || 0
        const position =
          originElement.top -
          fixedAreaBottom +
          cloneElementsHeighTotal +
          headerHeigh +
          topMenuHeigh
        // Only record the first time.
        hiddenElementsInfoInMobile[`${targetElementClassName}`] = position
      }

      if (
        hiddenElementsInfoInPC[`${targetElementClassName}`] === undefined &&
        !isMobile
      ) {
        const fixedScrollAreaHeight = container
          .querySelector('.fixed-scroll-area')
          .getBoundingClientRect().height
        // Only record the first time.
        hiddenElementsInfoInPC[`${targetElementClassName}`] =
          window.scrollY +
          originElement.top -
          fixedScrollAreaHeight -
          cloneElementsHeighTotal
        cloneElementsHeighTotal -= originElement.height
      }

      const hiddenElementsInfo = isMobile
        ? hiddenElementsInfoInMobile
        : hiddenElementsInfoInPC

      if (window.scrollY >= hiddenElementsInfo[`${targetElementClassName}`]) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })
  }
}

function switchHiddenElements(containerTarget = document) {
  const currentTab = containerTarget.querySelector('.tab__item.active')
  if (currentTab) {
    const currentTabId = currentTab.id.match(/^tab-label-(.+)$/)[1]
    const currentTabContent = containerTarget.querySelector(
      `#tab-content-${currentTabId}`
    )
    toggleClonedElements(currentTabContent)
  } else {
    toggleClonedElements(containerTarget)
  }
}

export default switchHiddenElements
