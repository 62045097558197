import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['faqContainer']

  connect() {
    this.appendScript()
  }

  composeStructuredData() {
    const faqStructuredData = [
      ...this.faqContainerTarget.querySelectorAll('.faq'),
    ].map((element) => ({
      '@type': 'Question',
      name: element.querySelector('.faq__item--question .faq__item-content')
        .innerText,
      acceptedAnswer: {
        '@type': 'Answer',
        text: element.querySelector('.faq__item--answer .faq__item-content')
          .innerText,
      },
    }))

    const structuredDataText = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqStructuredData,
    }

    return JSON.stringify(structuredDataText)
  }

  appendScript() {
    const script = document.createElement('script')
    script.setAttribute('type', 'application/ld+json')
    script.textContent = this.composeStructuredData()
    document.head.appendChild(script)
  }
}
