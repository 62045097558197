import { Controller } from '@hotwired/stimulus'
import ScrollHint from 'scroll-hint'

export default class extends Controller {
  connect() {
    this.scrollHint = new ScrollHint('.scroll-area__render-scroll', {
      suggestiveShadow: true,
      i18n: {
        scrollable: 'スクロールできます',
      },
    })
  }
}
