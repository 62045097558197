import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['pulldownSelect']

  select_option() {
    this.pulldownSelectTarget.classList.add('text-blueGray-700')
  }

  select_date() {
    this.pulldownSelectTarget.classList.add('text-blueGray-700')
    this.pulldownSelectTarget.parentNode.childNodes[2].classList.add(
      'text-blueGray-700'
    )
    this.pulldownSelectTarget.parentNode.childNodes[4].classList.add(
      'text-blueGray-700'
    )
  }

  select_year_month() {
    this.pulldownSelectTarget.classList.add('text-blueGray-700')
    this.pulldownSelectTarget.parentNode.childNodes[4].classList.add(
      'text-blueGray-700'
    )
  }
}
