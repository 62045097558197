import { Controller } from '@hotwired/stimulus'
import { clearCookie } from '../../utils/cookies'

export default class extends Controller {
  connect() {
    this.conversionKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
      'gclid',
      'yclid',
    ]

    this.conversionKeys.forEach((key) => clearCookie(key))
  }
}
