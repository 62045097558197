import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['benefitButtonIcon', 'benefitList']

  toggle() {
    this.benefitButtonIconTarget.classList.toggle('rotate-90')
    this.benefitListTarget.classList.toggle('hidden')
  }
}
