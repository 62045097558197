import { Controller } from '@hotwired/stimulus'
import { addDays } from 'date-fns'
import { setCookie } from '../../utils/cookies'

export default class extends Controller {
  connect() {
    this.conversionKeys = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
      'gclid',
      'yclid',
    ]

    const expiresTime = addDays(new Date(), 7)
    const referer = window.location.origin
    const searchObject = new URLSearchParams(window.location.search)
    const path = '/'
    document.cookie = `referer=${referer}; expires=${expiresTime}; path =/`

    this.conversionKeys.forEach((key) => {
      const source = searchObject.get(key)
      if (source !== null) {
        const value = encodeURIComponent(source)
        setCookie(key, value, expiresTime, path)
      }
    })
  }
}
