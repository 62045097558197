import { Controller } from '@hotwired/stimulus'

// .div data-controller="compare-see-more"
//   display at first time
//   .tr(or others tag) data-compare-see-more-target="option"

//   hidden at first time
//   .tr(or others tag) class="hidden" data-compare-see-more-target="option"
//   .tr(or others tag) class="hidden" data-compare-see-more-target="option"

//   .button(or others tag) data-action="click->compare-see-more#seeMore"

// P.S. Add hidden class name of the options when server side render.

export default class extends Controller {
  static targets = ['option']

  seeMore(e) {
    this.optionTargets.forEach((option) => {
      if (option.classList.contains('hidden')) {
        option.classList.remove('hidden')
      }
    })

    // hidden see more button
    e.currentTarget.classList.add('hidden')
  }
}
