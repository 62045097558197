const findProductIdsWithSearchString = () => {
  const searchString = window.location.search

  if (searchString.length === 0) {
    return []
  }

  const searchObject = new URLSearchParams(searchString)
  return searchObject.getAll('company_choose[]')
}

export default findProductIdsWithSearchString
