import { Controller } from '@hotwired/stimulus'
import LocalStorage from '../../modules/local_storage'

// ****** example ****** //
// <%= link_to '見積もり', car_consultant_step1_path, data: { controller: 'check-products', action: 'click->check-products#clickHandler', item_id_on_step1: @item.id } %>

const getSelectedValue = (label) => label.getAttribute('data-selected-value')

export default class extends Controller {
  static targets = ['label']

  connect() {
    const pageType = this.currentPageType()
    const searchObject = new URLSearchParams(window.location.search)
    const productId = searchObject.get('product_id')
    this.storage = new LocalStorage()
    this.checkedValues = []
    this.getStorageValues(pageType)
    const isThanksPage = /^.+\/thanks$/.test(window.location.pathname)

    if (productId) {
      this.checkSpecifiedItems(productId)
    }

    if (productId === null && this.storageValues) {
      this.confirmItems()
    }

    if (isThanksPage) {
      this.storage.cleanUp(this.storageKey)
      this.storage.cleanUp('companies_item_ids_on_step1')
      this.storage.cleanUp('inflow_insurance_item_id')
      this.storage.cleanUp('inflow_insurance_item_name')
    }
  }

  currentPageType() {
    const searchObject = new URLSearchParams(window.location.search)
    const type = searchObject.get('type')
    if (type) {
      return type
    } else {
      return window.location.pathname.match(/\w+/g)[0] || ''
    }
  }

  getStorageValues(type) {
    this.storageKey = `${type}_item_ids_on_step1`
    this.storageValues = this.storage.getValues(this.storageKey)
  }

  // 一括見積もり and 見積もり links need to add this action.
  clickHandler(e) {
    this.setStep1CheckConfirmInfo(e)
    this.setInflowInsuranceItemInfo(e)
  }

  // 見積もり needs to add data-item-id-on-step1 attribute.
  setStep1CheckConfirmInfo(e) {
    const pageType = e.currentTarget.getAttribute('href').match(/\w+/g)[0]
    this.checkedValues = e.currentTarget
      .getAttribute('data-item-id-on-step1')
      .split(',')

    this.getStorageValues(pageType)
    this.storage.setValue(this.storageKey, this.checkedValues)
  }

  // setting action param link or button if you need to set item info
  // data-check-products-id-param=item.id
  setInflowInsuranceItemInfo(e) {
    const itemId = e.params.id ? e.params.id : ''
    const itemName = e.params.name ? e.params.name : ''
    this.storage.setValue('inflow_insurance_item_id', itemId)
    this.storage.setValue('inflow_insurance_item_name', itemName)
  }

  changeHandler() {
    this.checkedValues = []
    this.labelTargets.forEach((label) => {
      const checkBox = label.querySelector('input[type="checkbox"]')
      const isChecked = checkBox ? checkBox.checked : false

      if (isChecked) {
        this.checkedValues.push(getSelectedValue(label))
      }
    })
    this.storage.setValue(this.storageKey, this.checkedValues)
  }

  confirmItems() {
    const labelIds = []
    this.labelTargets.forEach((label) => labelIds.push(getSelectedValue(label)))
    const isExistInOptions = this.storageValues.some((value) =>
      labelIds.includes(value)
    )

    if (isExistInOptions) {
      this.checkSpecifiedItems(this.storageValues)
    }
  }

  checkSpecifiedItems(ids) {
    this.labelTargets.forEach((label) => {
      if (ids.includes(getSelectedValue(label))) return

      label.classList.remove('checked')
      label.querySelector('input[type="checkbox"]').removeAttribute('checked')
    })
  }
}
