import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputBoxWrap']

  selected() {
    this.inputBoxWrapTarget.classList.add('input-box--selected')
    this.inputBoxWrapTarget.classList.remove('input-box--error')
  }
}
