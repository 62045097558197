import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: { type: String, default: '' },
  }

  connect() {
    this.urlValue ||= this.element.href
  }

  confirm(e) {
    e.preventDefault()

    const ans = window.confirm('ここから先は外部のサイトへ移動します。')
    if (ans && !!this.urlValue) {
      window.open(this.urlValue)
      return true
    }

    return false
  }
}
