export default class LocalStorage {
  getValues(key) {
    this.key = key
    return JSON.parse(window.localStorage.getItem(this.key))
  }

  cleanUp(key) {
    this.key = key
    window.localStorage.removeItem(this.key)
  }

  setValue(key, value) {
    this.key = key
    this.value = value
    window.localStorage.setItem(this.key, JSON.stringify(this.value))
  }
}
