import { Controller } from '@hotwired/stimulus'

// div class='scroll-area scroll-area__render-scroll scroll-area__scrollbar-x-rotate' data-controller="rotate-scrollbar" data-rotate-scrollbar-target="container"
//   div class="scroll-area__render-scroll--content scroll-area__scrollbar-x-rotate" data-rotate-scrollbar-target="content"

export default class extends Controller {
  static targets = ['container', 'content']

  connect() {
    this.previousWindowScrollY = 0
    const rotateTargets = [this.containerTarget, this.contentTarget]
    this.checkRotated(rotateTargets)

    window.addEventListener('scroll', () => {
      this.isDownScroll = window.scrollY > this.previousWindowScrollY
      this.previousWindowScrollY = window.scrollY <= 0 ? 0 : window.scrollY // update how much the window scrolls down
      const contentTop = this.contentTarget.getBoundingClientRect().top
      const isBottomTiming =
        this.isDownScroll && contentTop < -5 && this.scrollbarPosition === 'top'
      const isTopTiming =
        !this.isDownScroll &&
        contentTop > -5 &&
        this.scrollbarPosition === 'bottom'

      if (isBottomTiming) {
        this.rotateScrollbar(rotateTargets)
      }

      if (isTopTiming) {
        this.rotateScrollbar(rotateTargets)
      }
    })
  }

  checkRotated(rotateTargets) {
    rotateTargets.forEach((target) => {
      const isRotated = target.classList.contains(
        'scroll-area__scrollbar-x-rotate'
      )
      if (isRotated) {
        return
      }
      target.classList.add('scroll-area__scrollbar-x-rotate')
    })

    this.scrollbarPosition = 'top'
  }

  rotateScrollbar(rotateTargets) {
    rotateTargets.forEach((target) => {
      const isRotated = target.classList.contains(
        'scroll-area__scrollbar-x-rotate'
      )
      if (isRotated) {
        target.classList.remove('scroll-area__scrollbar-x-rotate')
        this.scrollbarPosition = 'bottom'
      } else {
        target.classList.add('scroll-area__scrollbar-x-rotate')
        this.scrollbarPosition = 'top'
      }
    })
  }
}
