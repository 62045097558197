import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'collapseOpenButton',
    'collapseCloseButton',
    'collapseContent',
  ]

  connect() {
    this.toggleContent()
  }

  toggle() {
    this.collapseOpenButtonTarget.classList.toggle('hidden')
    this.collapseCloseButtonTarget.classList.toggle('hidden')
    this.collapseContentTarget.classList.toggle('active')
    this.toggleContent()
  }

  toggleContent() {
    if (this.collapseContentTarget.classList.contains('active')) {
      this.collapseContentTarget.style.height = `${this.collapseContentTarget.firstChild.offsetHeight}px`
    } else {
      this.collapseContentTarget.style.height = '0px'
    }
  }
}
