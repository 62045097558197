// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import "channels";
import Rails from '@rails/ujs'
import './alpine'
import '../controllers/front_stage'
import '../css/application.scss'
import '@fortawesome/fontawesome-free/css/all.css'

require.context('../assets/', true, /\.(svg|jpg|png)$/)
Rails.start()
