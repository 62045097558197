import { Controller } from '@hotwired/stimulus'
import ScrollHint from 'scroll-hint'
import syncScrollArea from '../../utils/sync_scroll_area'
import switchHiddenElements from '../../utils/switch_hidden_elements'
import LocalStorage from '../../modules/local_storage'
import {
  addFixedStylesToElementsInMobile,
  putFixedElementsToTableBottomInMobile,
  recordCurrentBodyHeight,
  updateFixedElementTopList,
  addPaddingTopForMainArea,
  displayFixedScrollAreaElement,
  removeMobileFixedStyles,
  fixedElementHandlerInPC,
  putFixedElementsToTableBottomInPC,
} from '../../utils/fixed_styles_setting'

// **** Preset example **** //

// **** Preset example **** //
// div data-controller="fixed-area" data-fixed-area-target="container" (data-default-position="66.78125" set attribute if the object on the header to use)
//  .is-fixed
//  #tab-content-${currentTabId} .is-fixed
//    div data-action="click->fixed-area#tabClickHandler
//    label(tab panel).active(default tab)
//  .is-fixed
//  div(tab content)
//    .is-fixed.is-fixed-in-tab-content
//    .is-fixed.is-fixed-in-tab-content fixed-scroll-area-container w-full overflow-hidden z-60 md:relative md:bg-transparent
//      .fixed-scroll-area z-60 w-full md:max-w-187.5 absolute
//  .main-scroll-area overflow-x-scroll (id if tabs exist)
//  .fixed-area-bottom

// Update for fixed area in PC screen
// Add class names 'fixed-scroll-area-container' for fixed area container
//  Add class names 'md:max-w-187.5 absolute' for fixed area

// general
// <div class="is-fixed fixed-scroll-area-container overflow-hidden z-60 bg-blueGray-50 w-full md:relative md:bg-transparent">
//  <%= render Blocks::FollowUpComponent.new(category: :consultant, link_path: car_consultant_step1_path) %>
//  <div class='fixed-scroll-area is-fixed-hidden-el-area z-60 w-full md:max-w-187.5 absolute'>
//    <%= render Estimate::TableComponent.new(items: @item_list) do |t| %>
//      <%= render partial: "fixed_area", locals: { t: t, f: f } %>
//    <% end %>
//  </div>
// </div>

// Include tab element
// <div class="is-fixed-in-tab-content fixed-scroll-area-container z-60 bg-blueGray-50 w-full overflow-hidden">
//   <%= render Blocks::FollowUpComponent.new %>
//
//   <div class='fixed-scroll-area is-fixed-hidden-el-area z-60 w-full md:max-w-187.5 absolute'>
//     <%= render Estimate::TableComponent.new(items: items) do |t| %>
//       <%= render partial: "fixed_area_clone_item", locals: { t: t, f: f, plan_code: plan_code } %>
//     <% end %>
//   </div>
// </div>

// First row have to add 'origin-fixed-scroll-area'(!important)
// tr origin-fixed-scroll-area

const getCurrentIdWithStorage = () => {
  const { pathname } = window.location
  const pageType = pathname.match(/\w+/g)[0] || ''
  const storage = new LocalStorage()
  const hasSearchString = window.location.search.length > 0
  const value = hasSearchString
    ? storage.getValues(`${pageType}_current_tab`)
    : null

  return value
}

export default class extends Controller {
  static targets = ['container']

  connect() {
    const fixedElements = Array.from(
      this.containerTarget.querySelectorAll('.is-fixed')
    )
    const { defaultPosition } = this.containerTarget.dataset
    this.startFixedPoint =
      defaultPosition !== undefined ? Number(defaultPosition) : 0
    const hasClonedElement =
      this.containerTarget.querySelector('.cloned-el') !== null
    const headerHeigh =
      document.querySelector('header').getBoundingClientRect().height || 0
    const topMenuHeigh =
      document
        .querySelector('[class^="header__sp-nav-menu-wrapper"]')
        .getBoundingClientRect().height || 0
    this.currentScreenStatus = ''
    this.previousWindowScrollY = 0

    // Subject1: Window resize event
    // 基底是 PC Fixed Styles, 當尺寸轉換，基於 PC Styles 加上 Mobile Styles, 因此切回 Mobile 尺寸只需要加回即可！
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768 && this.currentScreenStatus !== 'pc') {
        this.clearMobileFixedStyles()
        this.currentScreenStatus = 'pc'
      }

      if (window.innerWidth <= 768 && this.currentScreenStatus !== 'mobile') {
        this.addMobileFixedStyles()
        this.currentScreenStatus = 'mobile'
      }

      switchHiddenElements(this.containerTarget)
    })

    // Subject2: Window scroll event
    if (fixedElements) {
      window.addEventListener('scroll', () => {
        const isPC = window.matchMedia('(min-width: 768px)').matches
        const isMobile = window.matchMedia('(max-width: 768px)').matches

        if (this.removeAll === true) {
          return
        }

        this.isDownScroll = window.scrollY > this.previousWindowScrollY
        this.previousWindowScrollY = window.scrollY <= 0 ? 0 : window.scrollY // update how much the window scrolls down

        if (isMobile) {
          const isFixedTiming = window.scrollY >= headerHeigh + topMenuHeigh
          recordCurrentBodyHeight()
          putFixedElementsToTableBottomInMobile(this.isDownScroll)

          if (isFixedTiming) {
            this.fixedOnMobileScrolling()
          } else {
            this.clearMobileFixedStyles()
          }

          if (hasClonedElement) {
            switchHiddenElements(this.containerTarget)
            updateFixedElementTopList(this.startFixedPoint)
          }
        }

        if (isPC) {
          fixedElementHandlerInPC(this.containerTarget, this.isDownScroll)
          putFixedElementsToTableBottomInPC(
            this.containerTarget,
            this.isDownScroll
          )

          if (hasClonedElement) {
            switchHiddenElements(this.containerTarget)
          }
        }
      })
    }

    // Subject3: Sync fixed area and main area
    syncScrollArea(this.containerTarget)

    // Subject4: Put table scroll-hint
    this.scrollHint = new ScrollHint('.main-scroll-area', {
      suggestiveShadow: true,
      i18n: {
        scrollable: 'スクロールできます',
      },
    })
  }

  tabClickHandler() {
    syncScrollArea(this.containerTarget)
    const isPCScreen = window.matchMedia('(min-width: 768px)').matches

    if (isPCScreen) {
      this.clearMobileFixedStyles()
      return
    }

    const isSPFormContent =
      document.querySelector('.form-content').classList.contains('hidden') ===
      false
    if (isSPFormContent) {
      this.addMobileFixedStyles()
    }
  }

  fixedOnMobileScrolling() {
    const currentTabIdInStorage = getCurrentIdWithStorage()
    displayFixedScrollAreaElement(this.containerTarget)
    addFixedStylesToElementsInMobile(
      this.startFixedPoint,
      this.containerTarget,
      currentTabIdInStorage
    )
    addPaddingTopForMainArea(this.containerTarget, currentTabIdInStorage)
  }

  addMobileFixedStyles() {
    this.clearMobileFixedStyles()
    displayFixedScrollAreaElement(this.containerTarget)
    switchHiddenElements(this.containerTarget)
    putFixedElementsToTableBottomInMobile(true, this.containerTarget)
    addFixedStylesToElementsInMobile(this.startFixedPoint, this.containerTarget)
  }

  clearMobileFixedStyles() {
    const fixedElements = Array.from(
      this.containerTarget.querySelectorAll('.is-fixed')
    )
    const fixedElementsInTabContent = Array.from(
      this.containerTarget.querySelectorAll('.is-fixed-in-tab-content')
    )
    if (fixedElements.length.length !== 0) {
      removeMobileFixedStyles(fixedElements, this.containerTarget)
    }

    if (fixedElementsInTabContent.length !== 0) {
      removeMobileFixedStyles(fixedElementsInTabContent, this.containerTarget)
    }
  }
}
